<template>
  <aside class="aside-card">
    <HeaderNav :product="product" />
    <ProductLink :product="product" class="aside-card__img-w">
      <img
        class="aside-card__img"
        :src="product.getMainImage('medium')"
        :alt="product.getName()"
        loading="lazy"
      />
    </ProductLink>
    <Purchase
      class="aside-card__purchase"
      :product="product"
      :is-aside="true"
    />
  </aside>
</template>

<script setup>
import { Product } from "~/models/product.model";
import Purchase from "~/modules/product/ui/components/Purchase.vue";
import { useHeaderStore } from "~/store/headerStore";
import HeaderNav from "~/modules/shared/productCard/components/HeaderNav.vue";

const props = defineProps({
  product: { type: Product, required: true },
  navigationHeight: { type: Number, required: true },
});

const headerStore = useHeaderStore();
const { getHeightHeader } = storeToRefs(headerStore);

const headerHeight = () => {
  return getHeightHeader.value.header;
};
</script>

<style scoped lang="scss">
.aside-card {
  --space-top: 16px;
  position: sticky;
  top: calc(
    v-bind(navigationHeight) * 1px + var(--space-top) + v-bind("headerHeight()")
  );

  max-width: 356px;
  width: 100%;

  padding-block: 16px;

  @include mobile {
    display: none;
  }

  &__img {
    min-height: 264px;
    margin: 0 auto;
    padding: 4px;
  }

  &__purchase {
    padding-inline: 16px;
  }
}
</style>
